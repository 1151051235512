<template>
  <div>
    <ejs-dialog
      ref="couponRegistrationPopupDialog"
      header="쿠폰종류 상세"
      :allowDragging="true"
      :showCloseIcon="true"
      width="500"
      height="800"
      :close="dialogClose"
      :position="{ x: 'center' }"
      :enableResize="false"
      :isModal="true"
      :cssClass="
        isCreate
          ? $t('className.popup.productCodePopupAdd')
          : $t('className.popup.productCodePopupModify')
      "
    >
      <div class="window couponKindDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">쿠폰종류 상세</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li>
                          <erp-button
                            button-div="GET"
                            :is-icon-custom="true"
                            @click.native="showCouponHistory"
                          >
                            변경이력
                          </erp-button>
                        </li>
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :ignore="isHistoryPopupOpen"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="CouponRegistrationPopup.shortcuts.add"
                              :shortcut="{key: 'F3'}"
                              @click.native="saveDataWithRefresh"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :ignore="isHistoryPopupOpen"
                              :is-shortcut-button="true"
                              @click.native="deleteCouponInfo"
                              v-if="!isCreate"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="section-body" class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">쿠폰정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">할인종류</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="couponDcKind"
                                              v-model="couponInfo.dcKind"
                                              :dataSource="dcKindCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">쿠폰명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              ref="couponName"
                                              v-model="couponInfo.couponName"
                                              maxlength="100"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">쿠폰유형</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="couponKind"
                                              v-model="couponInfo.couponKind"
                                              :dataSource="couponKindCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">쿠폰시작번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              v-model="couponInfo.couponStartNo"
                                              :disabled="
                                                couponInfo.couponKind !== 'P'
                                              "
                                              :allowDot="false"
                                              :allowMinus="false"
                                              :display-comma="false"
                                              :propMaxLength="10"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div
                                      :class="[
                                        'title',
                                        {
                                          required:
                                            couponInfo.couponKind !== 'P',
                                        },
                                      ]"
                                    >
                                      쿠폰번호자릿수
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              ref="couponNoCipher"
                                              v-model="
                                                couponInfo.couponNoCipher
                                              "
                                              :disabled="
                                                couponInfo.couponKind === 'P'
                                              "
                                              :min="1"
                                              :max="10"
                                              :allowDot="false"
                                              :allowMinus="false"
                                              :propMaxLength="2"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">만료개월수</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              ref="expirMonthCnt"
                                              v-model="couponInfo.expirMonthCnt"
                                              :allowDot="false"
                                              :allowMinus="false"
                                              :propMaxLength="3"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">발행제한매수</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              v-model="couponInfo.pubLmttNmrs"
                                              :allowDot="false"
                                              :allowMinus="false"
                                              :propMaxLength="5"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">타인양도가능</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label for="giveFlagT">
                                                <input
                                                  type="radio"
                                                  id="giveFlagT"
                                                  :value="true"
                                                  v-model="couponInfo.giveFlag"
                                                />
                                                <i></i>
                                                <div class="label">가능</div>
                                              </label>
                                            </li>
                                            <li>
                                              <label for="giveFlagF">
                                                <input
                                                  type="radio"
                                                  id="giveFlagF"
                                                  :value="false"
                                                  v-model="couponInfo.giveFlag"
                                                />
                                                <i></i>
                                                <div class="label">불가능</div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">사용여부</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  v-model="couponInfo.useFlag"
                                                />
                                                <i></i>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">정렬번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              v-model="couponInfo.sortNo"
                                              :allowDot="false"
                                              :allowMinus="false"
                                              :propMaxLength="5"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">할인방법</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">할인방법</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="dcMethod"
                                              v-model="couponInfo.dcMethod"
                                              :dataSource="dcMethodCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                              @change="onDcMethodChanged"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div
                                      :class="[
                                        'title',
                                        {
                                          required:
                                            couponInfo.dcMethod !== 'PAYMT',
                                        },
                                      ]"
                                    >
                                      금액/할인율
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              ref="dcAmt"
                                              v-model="couponInfo.dcAmt"
                                              :allowMinus="true"
                                              :disabled="
                                                couponInfo.dcMethod === 'PAYMT'
                                              "
                                              :allow-dot="
                                                couponInfo.dcMethod === 'RATE'
                                              "
                                              :propMaxLength="
                                                couponInfo.dcMethod === 'AMT'
                                                  ? 13
                                                  : 3
                                              "
                                              :max="
                                                couponInfo.dcMethod === 'RATE'
                                                  ? 100
                                                  : undefined
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div
                                      :class="[
                                        'title',
                                        {
                                          required:
                                            couponInfo.dcMethod === 'PAYMT',
                                        },
                                      ]"
                                    >
                                      요금코드
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="paymtCode"
                                              v-model="couponInfo.paymtCode"
                                              :enabled="
                                                couponInfo.dcMethod === 'PAYMT'
                                              "
                                              :dataSource="paymtCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0103">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">사용 제한</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">사용매장</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="storeCode"
                                              v-model="couponInfo.storeCode"
                                              :dataSource="storeCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">영업구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              v-model="couponInfo.bsnCode"
                                              :dataSource="bsnCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">사용가능시간</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group time">
                                          <div class="form">
                                            <input-time
                                              v-model="couponInfo.startTime"
                                              :format="'HH:mm'"
                                            />
                                          </div>
                                        </li>
                                        <li class="item text">~</li>
                                        <li class="item form-group time">
                                          <div class="form">
                                            <input-time
                                              v-model="couponInfo.endTime"
                                              :format="'HH:mm'"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">중복할인가능</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  v-model="
                                                    couponInfo.dplctDcFlag
                                                  "
                                                />
                                                <i></i>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section
                        :class="[
                          'article-section',
                          'section-0104',
                          'accordion',
                          { close: !isCouponSendTextVisible },
                        ]"
                        v-if="couponInfo.couponKind !== 'P'"
                      >
                        <div class="section-header">
                          <div class="header-left">
                            <div
                              class="header-title"
                              @click="handleCouponSendTextHeaderClick"
                            >
                              모바일 전송 문구
                            </div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">SMS 전송</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  v-model="
                                                    couponInfo.smsSendFlag
                                                  "
                                                />
                                                <i></i>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field remarks">
                                    <div class="title">SMS 전송문구</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                              v-model="couponInfo.smsAditWords"
                                              :rows="10"
                                              :maxlength="400"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field image">
                                    <div class="title">이미지</div>
                                    <div class="content">
                                      <div class="upload-box">
                                        <div class="body-photo">
                                          <div
                                            v-if="couponInfo.imageURL"
                                            class="item"
                                            style="
                                              background-size: contain;
                                              background-position: center center;
                                              background-repeat: no-repeat;
                                            "
                                            :style="{
                                              backgroundImage:
                                                'url(' +
                                                couponInfo.imageURL +
                                                ')',
                                              width: '100%',
                                              height: '140px',
                                            }"
                                          />
                                        </div>
                                        <ejs-uploader
                                          ref="couponFileUploader"
                                          name="couponFileUploader"
                                          :buttons="{ browse: '' }"
                                          :allowedExtensions="
                                            couponImageFileUploaderExtensions
                                          "
                                          :multiple="false"
                                          maxFileSize="10000000"
                                          :selected="onFileSelect"
                                        />
                                        <erp-button
                                          v-if="couponInfo.imageURL"
                                          button-div="DELETE"
                                          class="delete-photo"
                                          :use-syncfusion-component-style="false"
                                          @click="onDeleteProfileImageClicked"
                                        />
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0105">
                        <div class="section-body">
                          <div class="body-data" v-if="!isCreate">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">등록자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item text">
                                          {{ couponInfo.insertName }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="isHistoryPopupOpen"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                @click.native="saveDataWithClose"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="dialogClose"> 닫기 </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <history-popup
      ref="historyPopup"
      v-if="isHistoryPopupOpen"
      @popupClosed="historyPopupClose"
    />
  </div>
</template>

<style scoped>
body .field.remarks textarea{border:none;width:100%;padding:3px 6px 2px;margin:-3px -6px -2px;background:transparent url(../../assets/images/common/focus-inactive.png) no-repeat left top;resize:none}
body .field.remarks textarea:focus{background-image:url(../../assets/images/common/focus-active.png)}
body .field .upload-box{position:relative}
body .field .body-photo{display:flex;align-items:center;border:0}
body .field .delete-photo{position:absolute;top:5px;right:5px;width:32px;height:32px;border:0;border-radius:500rem;background-image:url(../../assets/images/common/image-remove.png);background-size:70%;background-position:center;background-repeat:no-repeat;background-color:rgba(0,0,0,.5);cursor:pointer}
body .field .delete-photo:hover{opacity:.5}
body .field.image >>> .e-upload{position:absolute;border:none;top:0;left:0;height:100%}
body .field.image >>> .e-upload .e-file-select-wrap{padding:0!important;height:100%}
body .field.image >>> .e-upload .e-file-select-wrap .e-file-drop{display:none}
body .field.image >>> .e-upload .e-btn{font-weight:normal;font-family:'돋움',Dotum,Arial,Verdana,sans-serif}
body .field.image >>> .e-upload .e-file-select{position:absolute;top:0;left:0}
body .field.image >>> .e-upload .e-file-drop{display:none}
body .field.image >>> .e-upload .e-upload-files{display:none}
body .field.image >>> .e-upload .e-btn,body .field.image >>> .e-upload .e-btn:hover,body .field.image >>> .e-upload .e-btn:focus{box-shadow:none}
body .field.image >>> .e-upload .e-btn{display:block;background-color:transparent;width:100%;height:100%}
body .field.image >>> .e-upload .e-btn:hover{background-image:url(../../assets/images/common/image-upload.png);background-size:10%;background-position:center;background-repeat:no-repeat;opacity:.5}
</style>

<script>
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputTextarea from "@/components/common/text/InputTextarea";
import InputNumber from "@/components/common/InputNumber";
import InputTime from "@/components/common/datetime/InputTime";
import { validateFormRefs, uploadImage } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import HistoryPopup from "@/views/common/HistoryPopup";
import commonMixin from "@/views/layout/mixin/commonMixin";
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import GolfERPService from "@/service/GolfERPService";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

Vue.use(AsyncComputed);

const couponInfoInitData = Object.freeze({
  dcKind: null,
  couponName: null,
  couponKind: null,
  couponStartNo: null,
  couponNoCipher: null,
  expirMonthCnt: 0,
  pubLmttNmrs: 0,
  giveFlag: true,
  useFlag: true,
  sortNo: null,
  dcMethod: null,
  dcAmt: 0,
  paymtCode: null,
  storeCode: null,
  bsnCode: "%",
  startTime: null,
  endTime: null,
  dplctDcFlag: true,
  smsSendFlag: true,
  smsAditWords: null,
  imageURL: null,
  imageId: null,
  insertName: null,
  insertDt: null,
});

export default {
  name: "CouponRegistrationPopup",
  components: {
    InputTextarea,
    InputText,
    HistoryPopup,
    InputTime,
    InputNumber,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      couponImageFileUploaderExtensions: ".png, .jpg, .jpeg, .gif, .bmp",
      isCouponSendTextVisible: false,
      isCouponImageUploadedAndNotSaved: false,
      dcKindCodes: commonCodesGetCommonCode("DC_KIND"),
      dcMethodCodes: commonCodesGetCommonCode("DC_METHOD"),
      couponKindCodes: commonCodesGetCommonCode("COUPON_KIND"),
      paymtCodes: commonCodesGetCommonCode("PAYMT_CODE"),
      dropdownListCodesOptions: { text: "comName", value: "comCode" },
      isHistoryPopupOpen: false,
      couponInfo: JSON.parse(JSON.stringify(couponInfoInitData)),
      kindId: null,
    };
  },
  beforeDestroy() {
    if (this.isCouponImageUploadedAndNotSaved) {
      this.deleteCouponFile();
    }
  },
  watch: {
    couponInfo: {
      deep: true,
      handler(data) {
        if (data.couponKind === "M") {
          data.couponStartNo = null;
        }
        if (data.couponStartNo) {
          this.couponInfo.couponNoCipher = data.couponStartNo.toString().length;
        }
      },
    },
  },
  asyncComputed: {
    async storeCodes() {
      const response = await GolfERPService.fetchStores(true);
      return commonCodesGetCommonCode("STORE_CODE").filter((data) => {
        return response.find((rData) => rData.code === data.comCode);
      });
    },
  },
  computed: {
    validateRefList() {
      return {
        couponDcKind: { required: true },
        couponName: { required: true },
        couponKind: { required: true },
        couponNoCipher: { required: true },
        expirMonthCnt: { required: true },
        dcMethod: { required: true },
        dcAmt: {
          required: this.couponInfo.dcMethod !== "PAYMT",
          min: 1,
          max: this.couponInfo.dcMethod === "RATE" ? 100 : undefined,
        },
        paymtCode: { required: this.couponInfo.dcMethod === "PAYMT" },
      };
    },
    validateRefSecondList() {
      return {
        storeCode: { required: true },
      };
    },
    bsnCodes() {
      const result = commonCodesGetCommonCode("BSN_CODE").filter(
        (data) => data.comCode !== "CLOSE"
      );
      result.unshift({ comCode: "%", comName: "전체" });
      return result;
    },
    isCreate() {
      return !this.kindId;
    },
  },
  methods: {
    validateFormRefs,
    showPopup(kindId) {
      this.initCouponData(kindId);
      this.fetchCouponUnitDetail(this.kindId);
      this.$refs.couponRegistrationPopupDialog.show();
    },
    async fetchCouponUnitDetail(kindId) {
      if (this.isCreate) {
        return;
      }
      const data = await GolfErpAPI.fetchCouponUnitDetail(kindId);
      this.couponInfo = { ...data };
    },
    dialogClose(isRefresh) {
      this.$emit("popupClosed", isRefresh ? true : null);
    },
    initCouponData(kindId) {
      this.kindId = kindId;
      this.couponInfo = JSON.parse(JSON.stringify(couponInfoInitData));
      this.couponInfo.dcKind = this.dcKindCodes[0].comCode;
      this.couponInfo.couponKind = this.couponKindCodes[0].comCode;
      this.couponInfo.dcMethod = this.dcMethodCodes[0].comCode;
    },
    onDeleteProfileImageClicked() {
      this.$refs.couponFileUploader.clearAll();
      if (this.couponInfo.imageURL && this.couponInfo.imageId) {
        this.deleteCouponFile();
        this.infoToast(this.$t("main.popupMessage.cleared"));
      }
    },
    async deleteCouponFile() {
      try {
        const imageId = JSON.stringify(this.couponInfo.imageId);
        this.couponInfo.imageURL = null;
        this.couponInfo.imageId = null;
        this.isCouponImageUploadedAndNotSaved = false;
        await GolfErpAPI.deleteImage(imageId);
      } catch (e) {
        console.error("imageDelete.err.===>", e);
      }
    },
    async onFileSelect(args) {
      const file = args.event.target.files[0];

      try {
        const returnData = await uploadImage(
          this.couponInfo.imageId,
          "COUPON_KIND",
          args.filesData,
          file
        );
        if (returnData) {
          this.couponInfo.imageId = returnData.imageId;
          this.couponInfo.imageURL = returnData.imageURL;
          this.isCouponImageUploadedAndNotSaved = true;
        }
      } catch (e) {
        alert(e.message);
      } finally {
        this.$refs.couponFileUploader.clearAll(); // 다시 upload 하지 않도록 clear
      }
    },
    checkValidation() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return false;
      }
      return this.validateFormRefs(this.validateRefSecondList);
    },
    saveCouponInfo() {
      return new Promise((resolve, reject) => {
        GolfErpAPI.postCouponUnitDetail(this.couponInfo)
          .then(() => {
            this.isCouponImageUploadedAndNotSaved = false;
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    saveDataWithClose() {
      if (!this.checkValidation()) {
        return;
      }
      this.saveCouponInfo()
        .then(() => {
          this.infoToast(this.$t("main.popupMessage.saved"));
          this.dialogClose(true);
        })
        .catch(() => {});
    },
    saveDataWithRefresh() {
      if (!this.checkValidation()) {
        return;
      }
      this.saveCouponInfo()
        .then(() => {
          this.infoToast(this.$t("main.popupMessage.saved"));
          this.initCouponData(null);
        })
        .catch(() => {});
    },
    async deleteCouponInfo() {
      if (this.kindId) {
        await GolfErpAPI.deleteCouponUnitDetail({ data: [this.kindId] });
        this.dialogClose(true);
      }
    },
    showCouponHistory() {
      GolfErpAPI.fetchCouponUnitHistory(this.kindId).then((res) => {
        this.isHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.onShowHistoryPopup(res);
        });
      });
    },
    historyPopupClose() {
      this.isHistoryPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },
    handleCouponSendTextHeaderClick() {
      this.isCouponSendTextVisible = !this.isCouponSendTextVisible;
      this.onScrollToEnd();
    },
    onScrollToEnd() {
      const container = document.querySelector("#section-body");
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
    onDcMethodChanged(args) {
      if (args.value === "PAYMT") {
        this.couponInfo.dcAmt = null;
      } else {
        this.couponInfo.paymtCode = null;
      }
    },
  },
};
</script>

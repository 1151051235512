<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field discountKind">
            <div class="title">할인종류</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="detailBsnDiv"
                  v-model="dcKind"
                  :dataSource="dcKindCodes"
                  :allowFiltering="false"
                  :fields="dcKindCodesOptions"
                  cssClass="lookup-condition-dropdown"
                ></ejs-dropdownlist>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input type="checkbox" v-model="useFlag" />
                      <i></i>
                      <div class="label">사용여부</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="fetchCouponUnitList"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">쿠폰 종류 목록</div>
              <div class="header-caption">
                [{{ numberWithCommas(dataSource.length) }}건]
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="save keyColor">
                  <erp-button
                    button-div="SAVE"
                    :ignore="isPopupOpened"
                    :is-shortcut-button="true"
                    :is-key-color="true"
                    @click.native="couponRegiSave"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="CouponRegistration.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      @click.native="couponRegistrationPopupOpen(null)"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="onDeleteButtonClicked"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="couponUnitListGrid"
                width="100%"
                :provides="gridProvides"
                :columns="gridColumns"
                :isNOColumnDisplay="true"
                :allowExcelExport="true"
                :selectionSettings="selectionOptions"
                :editSettings="couponUnitGridEditSettings"
                :dataSource="dataSource"
                @headerCellInfo="headerCellInfo"
                @queryCellInfo="queryCellInfo"
                @recordClick="recordClick"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <coupon-registration-popup
      ref="couponRegistrationPopup"
      v-if="showCouponRegistrationPopup"
      @popupClosed="couponRegistrationPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.discountKind .content .item {width: 120px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import Vue from "vue";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import CouponRegistrationPopup from "@/views/member-management/CouponRegistrationPopup";
import {
  Edit,
  ForeignKey,
  Resize,
  Filter,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper";
import commonMixin from "@/views/layout/mixin/commonMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "CouponRegistration",
  components: {
    CouponRegistrationPopup,
    ejsGridWrapper,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  mounted() {
    this.fetchCouponUnitList();
  },
  computed: {
    isPopupOpened() {
      return this.showCouponRegistrationPopup;
    },
    bsnCodes() {
      let result = commonCodesGetCommonCode("BSN_CODE");
      result.unshift({ comCode: "%", comName: "전체" });
      return result;
    },
    gridColumns() {
      return [
        {
          isPrimaryKey: true,
          visible: false,
          field: "kindId",
        },
        {
          headerText: "쿠폰정보",
          columns: [
            {
              headerText: "할인종류",
              field: "dcKind",
              groupCode: "DC_KIND",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "120",
            },
            {
              headerText: "쿠폰명",
              field: "couponName",
              textAlign: "Center",
              width: "180",
              type: "string",
            },
            {
              headerText: "쿠폰유형",
              field: "couponKind",
              groupCode: "COUPON_KIND",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "120",
            },
            {
              headerText: "지류쿠폰시작번호",
              field: "couponStartNo",
              textAlign: "Center",
              width: "150",
              type: "string",
            },
            {
              headerText: "쿠폰번호 자릿수",
              field: "couponNoCipher",
              textAlign: "Center",
              width: "150",
              type: "number",
              format: "N",
            },
            {
              headerText: "만료 개월수",
              field: "expirMonthCnt",
              textAlign: "Center",
              width: "120",
              type: "number",
              format: "N",
            },
            {
              headerText: "발행제한 매수",
              field: "pubLmttNmrs",
              textAlign: "Center",
              width: "130",
              type: "number",
              format: "N",
            },
            {
              headerText: "타인양도 여부",
              field: "giveFlag",
              type: "boolean",
              editType: "booleanedit",
              textAlign: "Center",
              displayAsCheckBox: true,
              width: "130",
            },
            {
              headerText: "사용여부",
              field: "useFlag",
              type: "boolean",
              editType: "booleanedit",
              textAlign: "Center",
              width: "105",
              displayAsCheckBox: true,
            },
            {
              headerText: "정렬",
              field: "sortNo",
              textAlign: "Center",
              width: "70",
              type: "string",
            },
          ],
        },
        {
          headerText: "할인방법",
          columns: [
            {
              headerText: "할인방법",
              field: "dcMethod",
              groupCode: "DC_METHOD",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "120",
            },
            {
              isNumericType: true,
              field: "dcAmt",
              headerText: "할인금액(율)",
              textAlign: "right",
              type: "string",
              width: 120,
            },
            {
              headerText: "요금코드",
              field: "paymtCode",
              textAlign: "Center",
              valueAccessor: this.paymtCodeValueAccessor,
              width: "120",
              type: "String",
            },
          ],
        },
        {
          headerText: "쿠폰 사용 제한",
          columns: [
            {
              headerText: "매장",
              field: "storeCode",
              groupCode: "STORE_CODE",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "120",
            },
            {
              headerText: "영업구분",
              field: "bsnCode",
              dataSource: this.bsnCodes,
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "120",
            },
            {
              headerText: "시간",
              textAlign: "Center",
              valueAccessor: this.timeValueAccessor,
              width: "90",
              type: "string",
            },
            {
              headerText: "중복할인여부",
              field: "dplctDcFlag",
              type: "boolean",
              editType: "booleanedit",
              textAlign: "Center",
              displayAsCheckBox: true,
              width: "120",
            },
          ],
        },
        {
          headerText: "SMS 전송",
          field: "smsSendFlag",
          type: "boolean",
          editType: "booleanedit",
          textAlign: "Center",
          displayAsCheckBox: true,
          width: "100",
        },
        // {
        //   headerText: "모바일 전송 문구",
        //   columns: [
        //     {
        //       headerText: "SMS 전송",
        //       field: "smsSendFlag",
        //       type: "boolean",
        //       editType: "booleanedit",
        //       textAlign: "Center",
        //       displayAsCheckBox: true,
        //       width: "100"
        //     },
        //     { headerText: "SMS 추가문구", field: "smsAditWords" },
        //     {
        //       headerText: "이미지",
        //       field: "imageURL",
        //       type: "string",
        //       template: this.imageTemplate,
        //       width: "180",
        //       allowFiltering: false
        //     }
        //   ]
        // }
      ];
    },
    dcKindCodes: {
      get() {
        const tmpCodes = commonCodesGetCommonCode("DC_KIND");
        tmpCodes.unshift({ comCode: "%", comName: "전체" });
        return tmpCodes;
      },
    },
  },
  data() {
    return {
      count: 0,
      dcKind: "%",
      useFlag: true,
      showCouponRegistrationPopup: false,
      gridProvides: [ForeignKey, Resize, Edit, Filter, ExcelExport],
      selectionOptions: { type: "Multiple" },
      dcKindCodesOptions: { text: "comName", value: "comCode" },
      couponUnitGridEditSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
      },
      dataSource: [],
      imageTemplate() {
        return {
          template: Vue.component("imageTemplate", {
            template: `<img :src="image" style="max-height: 150px"/>`,
            data() {
              return {
                data: {},
              };
            },
            computed: {
              image() {
                return this.data.imageURL;
              },
            },
          }),
        };
      },
    };
  },
  methods: {
    numberWithCommas,
    recordClick(args) {
      if (args.column && args.column.field === "couponName") {
        this.couponRegistrationPopupOpen(args.rowData.kindId);
      }
    },
    headerCellInfo(args) {
      if (args.cell.column.field === "couponName") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    queryCellInfo(args) {
      if (args.column.field === "couponName") {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (args.column.field === "dcAmt") {
        args.cell.innerText = `${this.numberWithCommas(args.data?.dcAmt)}${
          args.data?.dcMethod === "RATE" ? "%" : ""
        }`;
      }
      if ([
        "couponNoCipher",
        "expirMonthCnt",
        "pubLmttNmrs",
        "sortNo",
        "dcAmt",
      ].includes(args.column.field) && args.data[args.column.field] === 0) {
        args.cell.innerText = "-";
      }
      if (args.column && !args.data.useFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
    },
    timeValueAccessor(field, data) {
      data.startTime;
      data.endTime;
      return data.startTime + "~" + data.endTime;
    },
    paymtCodeValueAccessor(field, data) {
      if (data.dcMethod === "AMT") {
        return null;
      } else if (data.dcMethod === "RATE") {
        return null;
      } else if (data.dcMethod === "PAYMT") {
        return commonCodesGetCommonCode("PAYMT_CODE")
          .filter((paymtData) => paymtData.comCode === data.paymtCode)
          .map((paymtData) => paymtData.comName)[0];
      }
      return data.paymtCode;
    },
    inputNumberWithComma(str) {
      str = String(str);
      const strArr = str.split(".");
      if (strArr.length > 1) {
        //소숫점 형식은 소숫점으로 나눠서 정수 부분만 Comma 입력
        const firstNum = strArr[0].replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
        return firstNum + "." + strArr[1];
      } else {
        return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
      }
    },
    getTimeFormat(data) {
      let result = "";
      if (data && data.length === 4) {
        result = data.slice(0, 2) + ":" + data.slice(2, 4);
      }
      return result;
    },
    couponRegistrationPopupOpen(kindId) {
      this.showCouponRegistrationPopup = true;
      this.$nextTick(() => {
        this.$refs.couponRegistrationPopup.showPopup(kindId);
      });
    },
    couponRegistrationPopupClosed(isRefresh) {
      this.showCouponRegistrationPopup = false;
      if (isRefresh) {
        this.fetchCouponUnitList();
      }
    },
    onDeleteButtonClicked() {
      this.$refs.couponUnitListGrid.deleteRecord();
    },
    couponRegiSave() {
      const data = this.$refs.couponUnitListGrid.getBatchChanges();
      if (!this.$refs.couponUnitListGrid.validate()) {
        return;
      }
      if (data.deletedRecords) {
        const deleteKindIdList = data.deletedRecords.map((data) => data.kindId);
        GolfErpAPI.deleteCouponUnitDetail({ data: deleteKindIdList }).then(
          () => {
            this.infoToast(this.$t("main.popupMessage.saved"));
            this.fetchCouponUnitList();
          }
        );
      }
    },
    async fetchCouponUnitList() {
      const data = await GolfErpAPI.fetchCouponUnitList(
        this.dcKind,
        this.useFlag
      );
      this.dataSource = data;
    },
    onClickExcel() {
      this.$refs.couponUnitListGrid.excelExport();
    },
  },
};
</script>
